<script>
import { defineComponent, ref } from 'vue'
import LogoFanta from '@/assets/svg/logo-shield.svg'

export default defineComponent({
  name: 'ThePreloader',
  components: {
    LogoFanta
  },
  setup () {
    return {
      visible: ref(true)
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <div>
          <LogoFanta class="app-loading-logo" />
          <h3 class="app-loading-text">FantaWDW</h3>
          <svg class="app-loading-progress" viewBox="0 0 210 10">
            <line class="path" x1="0" y1="5" x2="210" y2="5" fill="none" stroke-width="5" />
            <line class="path" x1="0" y1="5" x2="210" y2="5" fill="none" stroke-width="5" />
            <line class="path" x1="0" y1="5" x2="210" y2="5" fill="none" stroke-width="5" />
          </svg>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #212121;
  text-align: center;
}

.app-loading-logo {
  display: block;
  width: rem(188px);
  height: rem(84px);
  margin: 0 auto rem(30px);
}

.app-loading-text {
  margin: 0 auto rem(50px);
  color: #fff;
}

.app-loading-progress {
  display: block;
  width: rem(188px);
  height: rem(10px);

  .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;

    &:nth-child(1) {
      animation: none;
      stroke: #666;
    }

    &:nth-child(2) {
      stroke: #bbbcbc;
    }

    &:nth-child(3) {
      animation-delay: 0.15s;
      stroke: #c00;
    }
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 220;
    stroke-dashoffset: -1;
  }

  100% {
    stroke-dasharray: 330, 330;
    stroke-dashoffset: -230;
  }
}
</style>
